.disappearance-alert {
    position: relative;
    font-size: 1.625rem;

    text-transform: uppercase;
    color: $white;
    background-color: $red-alert;
    text-align: center;
    font-weight: 700;
}

.uk-card__arrow {
  position: relative;
}

.arrow-up {
  z-index: 999;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid $white;
}

.cases__container {
  position: relative;
}

#case-details {
  z-index: 998;
  position: absolute;
}

.slider {

  &__links-list {
    padding-bottom: 10px;
  }
  &__slide {
    padding-bottom: 10px;
  }
  &__navigation {
    left: 0;
    right: 0;

    @media (max-width: $breakpoint-medium) {
        margin-top: 20px !important;
    }
  }
}