
@mixin hook-base-body() {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    letter-spacing: .03em;
}


/* Headings
 ========================================================================== */

@mixin hook-base-h1() {
    font-weight: 700;
}

@mixin hook-base-h2() {
    font-weight: 700;
}

@mixin hook-base-h3() {
    font-weight: 700;
}

@mixin hook-base-h4() {
    font-weight: 700;
}

@mixin hook-base-h5() {
    font-weight: 700;
}

@mixin hook-base-h6() {
    font-weight: 700;
}


/* Off canvas
 ========================================================================== */

/* Nav Bar
 ========================================================================== */
@mixin hook-navbar-container() {
    background-color: darken($global-muted-background, 10%);
}

// @mixin hook-navbar-dropdown() {
// }

/* Cards
 ========================================================================== */
@mixin hook-card() {
    background-color: $white;
}

@mixin hook-card-title() {
    font-weight: 700;
    color: $titles-color;
    line-height: 1.3;
}
@mixin hook-card-primary-title(){
}
@mixin hook-card-secondary-title(){
}

@mixin hook-card-body(){
    // font-size: 0.938rem;
}

@mixin hook-card-footer(){
    a {
        font-size: 0.85rem;


    }
}


@mixin hook-article-title(){
    @media (max-width: $breakpoint-medium) {
        font-size: 1.875rem;
    }
}



// @mixin hook-card(){ transition: box-shadow 0.1s ease-in-out; }

// @mixin hook-card-header(){}
// 
// @mixin hook-card-media(){}
// @mixin hook-card-media-top(){}
// @mixin hook-card-media-bottom(){}
// @mixin hook-card-media-left(){}
// @mixin hook-card-media-right(){}
// @mixin hook-card-title(){}
// @mixin hook-card-badge(){}
// @mixin hook-card-hover(){ box-shadow: $card-hover-box-shadow; }
// @mixin hook-card-default(){ box-shadow: $card-default-box-shadow; }
// @mixin hook-card-default-title(){}
// @mixin hook-card-default-hover(){ box-shadow: $card-default-hover-box-shadow; }
// @mixin hook-card-default-header(){ border-bottom: $card-default-header-border-width solid $card-default-header-border; }
// @mixin hook-card-default-footer(){ border-top: $card-default-footer-border-width  solid $card-default-footer-border; }
// @mixin hook-card-primary(){ box-shadow: $card-primary-box-shadow; }
// @mixin hook-card-primary-title(){}
// @mixin hook-card-primary-hover(){ box-shadow: $card-primary-hover-box-shadow; }
// @mixin hook-card-secondary(){ box-shadow: $card-secondary-box-shadow; }
// @mixin hook-card-secondary-title(){}
// @mixin hook-card-secondary-hover(){ box-shadow: $card-secondary-hover-box-shadow; }






@mixin hook-card-primary() {
    background-color: $red-alert;
}

@mixin hook-card-secondary() {
    background-color: $global-muted-color;
    color: $white;

    .uk-text-lead {
        font-size: .75rem;
        color: $white;

    }

    .uk-card-title {
        margin-top: 1rem;
        font-size: 1.625rem;
    }
}

/* Links
 ========================================================================== */
.uk-link {
    color: $primary-mcs;
    font-weight: 700;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    padding: .250rem .5rem;
    transition: all 125ms linear;
    font-size: 0.85rem;

    span {
        z-index: 10;
        position: relative;
    }
    
    &:after {
        content: ' ';
        width: 100%;
        height: 5%;
        background: $primary-mcs;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 0;
        transition: all 125ms linear;
    }

    &:hover {
        color: $white !important;
        text-decoration: none !important;

        &:after {
            height: 100%;
            background: $primary-mcs;
        }
    }

    &--helpline {
        color: $primary-helpline;

        &:after {
            background: $primary-helpline;
        }

        &:hover {
            color: $white;

            &:after {
                background: $primary-helpline;
            }
        }
    }

    &--forceRed {
        color: $primary-helpline !important;
    }

    &--allNews {
        font-size: 0.85rem;
    }
}

@mixin hook-link-muted() {
    font-weight: 700;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    padding: .250rem .5rem;
    transition: all 125ms linear;
    color: $white;

    span {
        z-index: 10;
        position: relative;
    }

    &:after {
        content: ' ';
        width: 100%;
        height: 5%;
        background: $white;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        z-index: 0;
        transition: all 125ms linear;
    }

    &--helpline {
        color: $white;

        &:after {
            background: $white;
        }
    }

    &--white {
        color: $white;

        &:after {
            background-color: $white;
        }

        &:hover {
            color: $primary-mcs;

            &:after {
                background-color: $white;
            }
        }

        &--helpline {
            &:hover {
                color: $primary-helpline;
            }
        }
    }
}
@mixin hook-link-muted-hover() {
    color: $primary-mcs;
    text-decoration: none;

    &.uk-link-muted--helpline:hover {
        color: $primary-helpline;
    }

    &:after {
        height: 100%;
        transform: translateY(0);
    }
}


@mixin hook-link-heading {
    color: $primary-mcs;

    &.uk-link-heading--helpline {
        color: $primary-helpline;
    }
}

@mixin hook-link-heading-hover {
    color: $titles-color;

    &.uk-link-heading--helpline:hover {
        color: $titles-color;
    }
}


// misc mixin
@mixin hook-card-misc() {
    .uk-card {
        &__icon {
            
            color: $white;
            background-color: $white;
            
            font-size: 4.375rem;
            line-height: 2.3;

            svg {
                max-width: 40%;
                max-height: 40%;

                path {
                    fill: $primary-mcs;
                }
            }

            &--helpline svg {
                path {
                    fill: $primary-helpline;
                }
            }
        }

        &--horizontal {
            color: $white;
            margin-bottom: 2rem;

            .uk-card {
                background-color: transparent;
            }

            .uk-card-body {
                padding-top: 0;
                padding-bottom: 0;
            }

            .uk-card-title {
                color: $white;
                font-size: 2rem;
        
            }
        }

        &--badged {
            margin-top: 80px !important;
            margin-bottom: 60px;

            @media (min-width: $breakpoint-medium) {
                margin-top: 60px;
                margin-bottom: 60px;
            }

            &-more {
                margin-top: 60px;
                margin-bottom: 60px;

                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 0px;
                }
            }
        }

        &--product {
            .uk-card-header {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
                border-bottom: 0;
            }
        }

        &--image {
            position: relative;
            height: 100%;
            background-color: $white;
            
            &--badged {
                .uk-card-header {
                    padding: 13px 0;
                }
                .uk-card-title {
                    font-size: 1.725rem !important;
                }
                .uk-card-body {
                    margin-top: -90px;
                    @extend .uk-flex-1;
                    @extend .uk-flex;
                    @extend .uk-flex-column;
                    @extend .uk-flex-between;

                    .uk-card-text {
                        @extend .uk-flex-1;
                    }
                }
            }

            .uk-text-lead {
        
                font-size: 1.625rem;
                font-weight: bold;
                margin-top: 0;
                line-height: 1.875rem;

                &--icon {
            
                    font-size: 2.5rem;
                    font-weight: bold;
                    margin-top: 1.625rem;
                    line-height: 2.938rem;
                }
            }

            .uk-card-body {
                padding-top: 0;
            }
        }

        &--product {
            .uk-card-title{
                font-size: 1.5rem;
            }

            .uk-card-body,
            .uk-card-footer {
                padding: 20px;
            }

            .uk-button {
                @extend .uk-button-primary;
                
                background-color: $primary-mcs;

                &:active, &:focus {
                    background-color: $primary-mcs;
                }
                &:hover {
                    background-color: darken($primary-mcs, 10%);
                }

                &--helpline {
                    background-color: $primary-helpline;

                    &:active, &:focus {
                        background-color: $primary-helpline;
                    }
                    &:hover {
                        background-color: darken($primary-helpline, 10%);
                    }
                }
            }
        }

        &--minicart {
            .uk-card-title{
                font-size: 1.5rem;
                padding: 20px 30px 0 30px;
            }

            .uk-button {
                @extend .uk-button-primary;
                margin-top: 1rem;
                
                background-color: $primary-mcs;

                &:active, &:focus {
                    background-color: $primary-mcs;
                }
                &:hover {
                    background-color: darken($primary-mcs, 10%);
                }

                &--helpline {
                    background-color: $primary-helpline;

                    &:active, &:focus {
                        background-color: $primary-helpline;
                    }
                    &:hover {
                        background-color: darken($primary-helpline, 10%);
                    }
                }
            }
        }

        &--teaser {
            position: relative;
            height: 100%;
            background-color: $white;
            
            @extend .uk-flex;
            @extend .uk-flex-column;
            @extend .uk-flex-between;

            &--half {
                @extend .uk-flex-middle;

                .uk-card-header,
                .uk-card-body,
                .uk-card-footer {
                    @media (min-width: $breakpoint-medium) {
                        width : 66%;
                    }
                }

                .uk-card-body {
                    @extend .uk-flex-1;
                }
            }

            &--muted {
                background-color: $global-muted-background;
            }

            .uk-card-title {

                &--number {
                    // Red Title for Numbers
                    font-size: 4rem;
                    font-weight: 700;
                    color: $primary-mcs;

                    &--helpline {
                        font-size: 4rem;
                        font-weight: 700;
                        color: $primary-helpline;
                    }
                }
            }

            .uk-text-lead {
        
                font-size: 1.625rem;
                font-weight: bold;
                margin-top: 0;
                line-height: 1.875rem;

                &--icon {
            
                    font-size: 2.5rem;
                    font-weight: bold;
                    margin-top: 1.625rem;
                    line-height: 2.938rem;
                }
            }

            .uk-card-body {
                padding-top: 0;
            }
        }

        &--more {
            position: absolute;
            margin-top: 0 !important;
            padding-bottom: 15px;
            // margin-left: 15px;
        }

        // Container for teasers
        &__container:not(.uk-card--badged) {
            margin-top: 0 !important;
            margin-bottom: 15px;
        }

        // Container for disappearance cards in list
        &__container--disappearance {
            margin-bottom: 15px;
            // transition: margin 400ms;
        }

        // Marged card for disapearances details opened
        &__container--marged {
            margin-bottom: 55px;
        }

        &--disappearance {

            border: solid 1px #e4e4e4;

            .uk-card-header {
                padding: 13px 10px;

                .uk-label {
                    display: block;
                    margin-top: 15px;
                }

                @media (min-width: $breakpoint-small) {
                    .uk-label {
                        display: inline-block;
                    }
                }
                @media (min-width: $breakpoint-medium) {
                    padding: 13px 20px;
                    .uk-label {
                        display: inline-block;
                        margin-top: 0;
                    }
                }
            }

            &:not(.uk-card--disappearance--slider) {
                img {
                    filter: grayscale(100%);
                }
            }
            

            .uk-card-body {
                padding: 20px 10px;

                @media (min-width: $breakpoint-small) {
                    padding: 20px 20px;
                }
            }

            .uk-card-title {
                font-size: 1.625rem;
                font-weight: 700;
            }

            .uk-text-meta {
        
            }

            .uk-card-footer {
                padding-top: 0;

                @media (max-width: $breakpoint-small-max) {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
                

                .uk-label-danger {
                    font-size: 0.75rem;
                    font-weight: 700;
            
                    padding: .425rem 0.5rem;
                }

                a {
                    font-size: 0.850rem;

                    &.disappearance-more{
                        @media (max-width: $breakpoint-small-max) {
                            padding: 0;
                        }
                    }
                }
            }

            .uk-card-footer .notice-help {
                i {
                    color: $primary-helpline;
                }
            }

            .child-description {
                line-height: 2;

                @media (min-width: $breakpoint-large) {
                    line-height: 3;
                }
            }
        }

        &--links {
            border: solid 1px #e4e4e4;

            .uk-card-body ul li {
                text-align: center;
            }
            .uk-card-body ul li::before {
                display: none;
            }

            &-empty {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
        }

        // For contents with image elements
        &--contents {

            background-color: transparent;

            @media (max-width: $breakpoint-small) {

                display: flex;
                margin-left: 0;

                > * {
                    padding-left: 0;
                }

                .uk-card-media-left,
                .uk-card-media-right {
                    order: 0;
                    // display: inline-block;
                    margin-bottom: 2rem;
                }
            }

            .uk-card-body {
                padding-top: 0;
                padding-right: 40px;

                @media (max-width: $breakpoint-medium) {
                    padding: 0;
                }
            }

            .uk-card-title {
                font-size: 2rem;
                line-height: 1.4;
                padding-bottom: 1rem;

                &--number {
                    font-size: 6.25rem;
                    margin-bottom: 0;
                    color: $primary-mcs;
                    
                    &--helpline {
                        color: $primary-helpline;
                    }
                }
            }

            h4.uk-card-title {
                text-align: center;
                margin: 0;
            }

            ul, ol {
                @media (min-width: $breakpoint-medium) {
                    padding-left: 0;
                }
            }
            @media (min-width: $breakpoint-medium) {
                // margin-bottom: 40px;
            }
        }

        &__price {
            font-weight: 700;
            margin-bottom: 1rem;
        }

        &--testimonial {
            background: transparent;

            small {
                margin-top: 1rem;
                margin-right: 1rem;
            }
        }

        &--news {
            background: transparent;

            img {
                filter: sepia(1);
            }

            &-muted {
                background-color: $global-muted-background;
            }
        }
    }
}

@mixin hook-section-misc() {
    .uk-section--teaser {
        position: relative;
        overflow: hidden;
        
        @media (min-width: $breakpoint-sm) {
            &:before {
                content: ' ';
                position: absolute;
                z-index: 0;
                
                width: 100%;
                height: 70%;

                background-color: $primary-mcs;
                display: block;

                bottom: 0;
                right: -19%;
                top: 25%;
                bottom: 0;
            }
        }

        @media (min-width: $breakpoint-medium) {
            &:before {
                right: -20%;
                transform: skew(-15deg);
            }
        }

        @media (min-width: $breakpoint-large) {
            &:before {
                
                bottom: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100%;

                right: -42%;
                transform: skew(-30deg);
            }
        }

        &--helpline {
            &:before {
                background-color: $primary-helpline;
            }
        }

        .uk-card--horizontal {

            @media (max-width: $breakpoint-sm-max) {
                margin-bottom: 5rem;
                position: relative;

                &:before {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    background-color: $primary-mcs;
                    display: block;
                    z-index: 0;

                    bottom: 0;
                    height: 85%;
                    right: 0;
                    bottom: 0;

                    top: 105px;
                    z-index: -1;
                }
            }

            &:first-child {
                margin-left: 0;
                margin-top: 0;
                @media (min-width: $breakpoint-sm) {
                    margin-top: 5rem;
                    margin-left: 5%;
                }
                @media (min-width: $breakpoint-medium) {
                    margin-left: 17%;
                }
                @media (min-width: $breakpoint-large) {
                    margin-top: 0;
                    margin-left: 20%;
                }
            }

            &:nth-child(2) {
                @media (min-width: $breakpoint-sm) {
                    margin-top: 1rem;
                    margin-left: 5%;
                }
                @media (min-width: $breakpoint-medium) {
                    margin-left: 10%;
                }
                @media (min-width: $breakpoint-large) {
                    margin-top: 0;
                    margin-left: 5%;
                }
            }

            &:last-child {
                // margin-left: 30%;
                @media (min-width: $breakpoint-sm) {
                    margin-top: 1rem;
                    margin-left: 5%;
                }
                @media (min-width: $breakpoint-medium) {
                    margin-left: -3%;
                }
                @media (min-width: $breakpoint-large) {
                    margin-top: 0;
                    margin-left: -15%;
                }
            }
        }
    }

    .uk-section--footer {
        padding-top: 35px;
        color: $white !important;

        a {
            color: $white !important;
        }

        .logo__section {
            margin-top: 40px;
        }
        

        @media (max-width: $breakpoint-small) {
            .logo__section {
                display: flex;
                justify-content: space-between;
            }
        }          

        @media (min-width: $breakpoint-small) {
            .logo__wrapper {
                margin-left: 18px;
            }        
        }        

        @media (min-width: $breakpoint-medium) {
            .logo__section {
                margin-top: 0;
            }
            .logo__wrapper {
                margin-left: 40px;
            }        
        }

        svg {
            width: auto;
            height: 55px;

            &.footer-logo {
                &--mcs {
                  height: 45px;
                  margin-top: 10px;
                }
                &--mce {
                  height: 51px;
                  margin-top: 19px;
                }
                &--icmec {
                  height: 70px;
                }
            }
        }
    }

    .uk-section--bordered {
        border-top: 30px solid $primary-mcs;
        border-bottom: 30px solid $primary-mcs;
        padding-top: 25px;
        padding-bottom: 25px;

        &-helpline {
            border-top-color: $primary-helpline;
            border-bottom-color: $primary-helpline;
        }
    }
}

@mixin hook-navbar-dropdown() {
    text-align: left;
    padding: 10px;
}

@mixin hook-offcanvas-bar() {

    background-color: darken($global-muted-background, 5%);

    hr {
        border-top-color: #666 !important;
    }

    .uk-button--teaser {
        .uk-text-large {
            font-size: 1.125rem;
        }
    }
}

@mixin hook-button-default() {
    &--donate { 
        border: none;
        text-transform: uppercase;
        color: $red-alert !important;
        padding: 0 15px !important;
        font-weight: 700;
        transition: background-color 0.5s ease-in;

        &:hover {
            background-color: $red-alert !important;
            color: $white !important;
        }
    }
    &--Helpline {
        border: solid 1px $primary-helpline !important;
        color: $primary-helpline !important;
        padding: 0 15px;
        font-weight: 700;
        transition: background-color 0.5s ease-in;

        &:hover {
            background-color: $primary-helpline !important;
            color: $white !important;
        }
    }
    &--Mcs {
        border: solid 1px $primary-mcs !important;
        color: $primary-mcs !important;
        padding: 0 15px;
        font-weight: 700;
        transition: background-color 0.5s ease-in;

        &:hover {
            background-color: $primary-mcs !important;
            color: $white !important;
        }
    }
}

@mixin hook-button-primary() {
    &--helpline {
        background-color: $primary-helpline !important;
        font-weight: 700;
    }
    &--mcs {
        background-color: $primary-mcs !important;
        font-weight: 700;
    }
}

@mixin hook-button() {
    &--teaser {

        display: flex;
        flex-direction: row;
        align-items: center;

        line-height: 100%;
        padding: .725rem .725rem;
        font-size: 1rem;
        color: $white !important;

        span {
            display: inline-block;
            float: initial;
            width: 100%;
            text-align: left;
            font-size: 0.725rem;
        }

        svg {
            width: 70px;
            margin-bottom: 0;
        }

        .uk-text-large {
            line-height: 1.2;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 1.6rem;
            letter-spacing: 0px;
        }

        .uk-heading-large {
            font-size: 1.25rem;
            line-height: 1.4;
            font-weight: 700;
            color: $white;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
        }

        .uk-text-meta {
            font-size: 0.875rem;
            line-height: 1.4;
            color: $white;
            text-transform: uppercase;
        }

        .icon-attention-alt {
            font-size: 3.5rem;
            margin: 0;

            &:before {
                margin: 0;
            }
        }

        &--mcs {
            margin: 0 !important;
            width: 100%;
            max-width: 100%;
            span {
                text-align: center;
            }
        }

        @media (min-width: $breakpoint-small) {
            text-align: left;
            margin-right: 1rem;
            margin-bottom: 5px;
            align-items: center;
            display: inline-flex;

            span {
                font-size: 0.925rem;
            }

            .uk-text-large {
                font-size: 1.8rem;
            }
        }

        @media (min-width: $breakpoint-medium) {
            svg {
                width: 50px;
            }

            span {
                font-size: 1rem;
            }

            span:not(.uk-text-large){
                margin-left: 20px !important;
            }

            &--mcs {
                span:not(.uk-text-large) {
                    margin-left: 0 !important;
                }
            }
        }

        @media (max-width: $breakpoint-large) {
            width: 100%;

            span {
                display: block;
                float: right;
                margin-left: 10px;
            }
        }
    }

    &--green {
        border: 1px solid transparent;
        background-color: $green-alert;
        color: $white !important;

        &:hover {
            color: $white !important;
            background-color: darken($green-alert, 10%);
        }

        .uk-text-large {
            font-size: 1.4rem;
            letter-spacing: .03em;
        }

        @media (min-width: $breakpoint-small) {
            .uk-text-large {
                font-size: 1.5rem;
            }
        }
    }
}


@mixin hook-base-heading() {
}

/* Hero
 ========================================================================== */
@mixin hook-heading-hero() {
    line-height: 1.25;
}


@mixin hook-button-text() {
    color: $red-alert;
    text-transform: uppercase;
    padding-top: 1rem;
    font-weight: 700;
}

@mixin hook-article-title() {
    font-weight: 700;
    // text-transform: uppercase;
    color: $titles-color;
}

@mixin hook-list-misc() {
    .uk-list--socials {
        margin-top: 0;

        @media (max-width: $breakpoint-xsmall-max) {
            display: flex;
            justify-content: space-between;
        }



        li {
            display: inline-block;
            margin-top: 0;
            margin-right: 0.3rem;
            font-size: 2.25rem;

            @media (max-width: $breakpoint-xsmall-max) {
                span:before {
                    margin: 0;
                }
            }

            @media (min-width: $breakpoint-small) {
                margin-right: 0.3rem;
                font-size: 2.25rem;
            }

        }

        > li:nth-child(n+2) {
            margin-top: 0;
        }
    }
}

@mixin hook-label-danger() {
    border-radius: 0;
    font-size: .75rem;
    padding: .625rem 1rem;
}

@mixin hook-card-media-top {
    &--badge {
        position: relative;
        width: 180px;
        height: 180px;
        top: -90px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin hook-card-media-left {
    &--badge {
        width: 180px;
        height: 180px;
    }
    &--badge-teaser {
        width: 210px;
        height: 210px;

        @media (max-width: $breakpoint-sm-max) {
            margin: 0 auto 2rem auto;

        }
    }
}

/* ========================================================================
   Component: Slidenav
 ========================================================================== */
@mixin hook-slidenav-container {
    padding-left: 15px;

    @media (min-width: $breakpoint-medium) {
        padding-left: 0;
    }
}

@mixin hook-slidenav {
    padding: 0;
}

@mixin hook-slidenav-previous {
    width: 10% !important;
    text-align: left;

    @media (min-width: $breakpoint-small) {
        text-align: center;
    }
    @media (min-width: $breakpoint-medium) {
        text-align: right;
        width: calc(100% * 1 / 3.001) !important;
    }
}
@mixin hook-slidenav-next {
    width: 10% !important;
    text-align: right;

    @media (min-width: $breakpoint-small) {
        text-align: center;
    }
    @media (min-width: $breakpoint-medium) {
        text-align: left;
        width: calc(100% * 1 / 3.001) !important;
    }
}

/* ========================================================================
   Component: Accordion
 ========================================================================== */

 @mixin hook-accordion {
    .uk-accordion-title {
        font-size: 1.25rem;
        font-weight: 700;
    }

    .uk-open .uk-accordion-title {
        font-weight: 700;
        color: $accordion-title-color !important;
    }
 }
 @mixin hook-accordion-content {
    font-size: 0.938rem;
 }

/* ========================================================================
   Component: Heading line
 ========================================================================== */
 @mixin hook-heading-line {
    border-bottom-color: $primary-mcs !important;

    &--helpline {
        border-bottom-color: $primary-helpline;
    }
 }
