// Global colors
$primary-mcs: #0059B2;
$primary-helpline: #DD1C1A;
$white: #FFFFFF;
$red-alert: #DD1C1A;
$green-alert: #3AAA4C;
$global-color-alt: #333333;
$titles-color: #505050;

// $global-link-color: $primary-mcs;
// $link-muted-color: $primary-mcs;

//  Links colors
.mcs {
    a {
        color: $primary-mcs;
        &:hover {
            color: $primary-mcs;
        }

        &.helpline {
            color: $primary-helpline;
        }
    }

    ::selection {
        background: $primary-mcs;
    }
}
.helpline {
    a {
        color: $primary-helpline;
        &:hover {
            color: $primary-helpline;
        }

        &.helpline {
            color: $primary-mcs;
        }
    }

    ::selection {
        background: $primary-helpline;
    }
}

$breakpoint-sm: 768px !default;
$breakpoint-sm-max: ($breakpoint-sm - 1) !default;


$global-muted-color: #A7A7A7;
$global-muted-background: #FAFAFA;
$global-danger-background: $red-alert;

$global-inverse-color: #fff !default;
$inverse-global-color: rgba($global-inverse-color, 1) !default;

$heading-hero-font-size: 4.75rem;
$heading-hero-font-size-l : 4.75rem;

// Articles font sizes
$article-title-font-size-m: 1.625rem;
$text-lead-font-size: 1.250rem;

// Cards title
$card-title-font-size: 1.625rem;
$card-title-line-height: 1.1;


$base-margin-vertical: 12px;

//
// Navigation
//
$navbar-dropdown-width:                         250px;
// $navbar-dropdown-padding:                       0;

//
// Typography
//

$global-font-family:                            'Roboto', "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
$global-font-size:                              100% !default;
$global-line-height:                            1.4 !default;      // 24px
// $base-body-font-weight:                         300 !default;


//
// Colors
//

$global-color:                                   #666666 !default;
$base-em-color:                                  $global-color-alt;


// $global-large-margin
$global-large-margin:                          50px!default;
$global-xlarge-margin:                          50px!default;