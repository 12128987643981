/**
 * @author zhixin wen <wenzhixin2010@gmail.com>
 */

.ms-parent {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  padding: 0;
}

.ms-choice {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0;
  overflow: hidden;
  cursor: pointer;
  border: none;
  text-align: left;
  white-space: nowrap;
  line-height: 38px;
  color: #444;
  text-decoration: none;
  // -webkit-border-radius: 4px;
  // -moz-border-radius: 4px;
  // border-radius: 4px;
  background-color: transparent;

  &.disabled {
    background-color: #f4f4f4;
    background-image: none;
    border: 1px solid #ddd;
    cursor: default;
  }

  > {
    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      padding-left: 8px;
      background-color: #fff;

      &.placeholder {
        color: #999;
      }
    }
    div {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      height: 25px;
      // background: url('multiple-select.png') left top no-repeat;

      &.open {
        background-position: right top;
      }
    }
  }
}

.ms-drop {
  width: 100%;
  overflow: hidden;
  display: none;
  margin-top: 0;
  padding: 0;
  position: absolute;
  z-index: 1000;
  background: #fff;
  color: #000;
  border: 1px solid #aaa;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;

  &.bottom {
    top: 100%;
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  }

  &.top {
    bottom: 100%;
    -webkit-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.15);
  }
}

// .ms-search {
//   display: inline-block;
//   margin: 0;
//   min-height: 26px;
//   padding: 4px;
//   position: relative;
//   white-space: nowrap;
//   width: 100%;
//   z-index: 10000;

//   input {
//     width: 100%;
//     height: auto !important;
//     min-height: 24px;
//     padding: 0 20px 0 5px;
//     margin: 0;
//     outline: 0;
//     font-family: sans-serif;
//     font-size: 1em;
//     border: 1px solid #aaa;
//     -webkit-border-radius: 0;
//     -moz-border-radius: 0;
//     border-radius: 0;
//     -webkit-box-shadow: none;
//     -moz-box-shadow: none;
//     box-shadow: none;
//     background: #fff url('multiple-select.png') no-repeat 100% -22px;
//     background: url('multiple-select.png') no-repeat 100% -22px, -webkit-gradient(linear, left bottom, left top, color-stop(0.85, white), color-stop(0.99, #eeeeee));
//     background: url('multiple-select.png') no-repeat 100% -22px, -webkit-linear-gradient(center bottom, white 85%, #eeeeee 99%);
//     background: url('multiple-select.png') no-repeat 100% -22px, -moz-linear-gradient(center bottom, white 85%, #eeeeee 99%);
//     background: url('multiple-select.png') no-repeat 100% -22px, -o-linear-gradient(bottom, white 85%, #eeeeee 99%);
//     background: url('multiple-select.png') no-repeat 100% -22px, -ms-linear-gradient(top, #ffffff 85%, #eeeeee 99%);
//     background: url('multiple-select.png') no-repeat 100% -22px, linear-gradient(to bottom, #ffffff 85%, #eeeeee 99%);
//     -webkit-box-sizing: border-box;
//     -khtml-box-sizing: border-box;
//     -moz-box-sizing: border-box;
//     -ms-box-sizing: border-box;
//     box-sizing: border-box;
//   }

//   -webkit-box-sizing: border-box;
//   -khtml-box-sizing: border-box;
//   -moz-box-sizing: border-box;
//   -ms-box-sizing: border-box;
//   box-sizing: border-box;
// }

.ms-drop {
  ul {
    overflow: auto;
    margin: 0;
    padding: 5px 8px;

    > li {
      list-style: none;
      display: list-item;
      background-image: none;
      position: static;

      span {
        @extend .uk-form-label;
      }

      .disabled {
        opacity: .35;
        filter: Alpha(Opacity = 35);
      }

      &.multiple {
        display: block;
        float: left;
      }

      &.group {
        clear: both;
      }

      &.multiple label {
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      input[type='checkbox'] {
        @extend .uk-checkbox;
        margin-right: 5px;

        &:checked {
          @extend .uk-checkbox:focus;
        }
        &:focus {
          @extend .uk-checkbox:focus;
        }
        &:indeterminate {
          @extend .uk-checkbox:indeterminate;
        }
        &:indeterminate:focus {
          @extend .uk-checkbox:indeterminate:focus;
        }
      }

      label {
        font-weight: normal;
        display: block;
        white-space: nowrap;
        cursor: pointer;

        &.optgroup {
          font-weight: bold;
        }
      }
    }
  }

  input {
    &[type="radio"] {
      vertical-align: middle;
      margin: 5px;
    }
  }

  .ms-no-results {
    display: none;
  }
}