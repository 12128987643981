
// Top Navigation bar

.nav-wrapper {
    &--top {
        background-color: darken($global-muted-background, 10%);
        z-index: 9999;
        position: relative;
    }

    &--main {
        background-color: $white;
    }

}

.uk-navbar {
    
    &-left {

        position: relative;

        .navbar-logo {
            padding: 0;
            // margin-left: 15px;
            
            transition: visibility 0s, opacity 0.5s linear;

            img {
                opacity: 0;
            }

            &.logo-mcs {
                position: relative;
                top: 24px;
                left: 0;
                z-index: 99;
                padding-left: 1rem;

                @media (min-width: $breakpoint-medium) {
                    top: 25px;
                }

                svg {
                    width: 100%;
                }
            }

            &.logo-helpline {
                padding-left: 1rem;
                @extend .uk-flex-inline; 
                @extend .uk-flex-bottom; 

                svg {
                    margin-bottom: -1px;
                }
            }

            &-desk-small {
                padding: 0;
                position: absolute;
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s, opacity 1s linear;

                .logo-injected {
                    width: auto;
                    height: 55px;
                }
                &.logo-mcs {
                    bottom: -13px;
                    margin-left: 1rem;
                }

                &.logo-helpline {
                    .logo-injected {
                        height: 42px;
                    }
                }
            }

            &-phone {
                .logo-injected {
                    // width: auto;
                    // height: 55px;
                }
                &.logo-mcs {
                    top: 27px;

                    svg {
                        // width: 50px;
                    }
                }
                &.logo-helpline {
                    svg {
                        // margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.uk-navbar {
    
    &-centered {
        padding-left: 0.5rem;

        .uk-navbar-nav > li > a {
            margin: 0 15px;
        }
    }

    &-right {
        .uk-navbar-item {
            padding: 0;
            margin-right: -15px;
        }
    }

    &-nav {
        height: 100%;

        > li > a {
            font-weight: 700;
            min-height: 2rem;
            padding: 0;
        }

        li.uk-active {
            .uk-navbar-dropdown-nav {

                a {
                    border-bottom: solid 0px transparent;

                    &.uk-active,
                    &:hover {
                        color: $primary-mcs;
                    }
                }

                &--helpline {
                    a {
                        &.uk-active,
                        &:hover {
                            color: $primary-helpline;
                        }
                    }
                }
            }
        }

        li.uk-active {
            a {
                color: $primary-mcs;
                border-bottom: solid 1px $primary-mcs;
            }

            &--helpline {
                a {
                    // background-color: $primary-helpline;
                    color: $primary-helpline;
                    border-bottom: solid 1px $primary-helpline;
                }
            }

            .uk-navbar-dropdown {
                padding: 0;
                margin-top: 0;
            }

            .uk-navbar-dropdown-nav {

                padding: 10px;
                // background-color: $primary-mcs;

                a {
                    text-align: left;
                    color: $navbar-dropdown-nav-item-color;
                    background-color: transparent;

                    &.uk-active,
                    &:hover {
                        color: $primary-mcs;
                        background-color: transparent;
                    }
                }

                &--helpline {
                    a {
                        &.uk-active,
                        &:hover {
                            color: $primary-helpline;
                        }
                    }
                }
            }

            &--helpline {
                a.uk-active,
                a:hover {
                    // background-color: $primary-helpline;
                }
            }
        }

        li {
            .uk-navbar-dropdown-nav {
                a.uk-active,
                a:hover {
                    background-color: transparent;
                }
            }
            .uk-navbar-dropdown-nav .uk-active a, {
                border-bottom: solid 0px transparent;
            }
        }
    }

    &--donate {
        li {
            .uk-dropdown {
                padding: 0;

                a {
                    text-align: left;
                    background-color: transparent !important;
                    color: $red-alert !important;
                    border: none;
                    border-left: 5px solid $white;
                    padding-left: 10px !important;

                    &:hover {
                        text-align: left;
                        background-color: transparent !important;
                        color: $red-alert !important;
                        border: none;
                        border-left: 5px solid $red-alert;
                    }
                }
            }
        }
        .uk-active {
            a {
                background-color: $red-alert !important;
                color: $white !important;
                border: none;

                .uk-dropdown {
                    a {
                        text-align: left;
                        background-color: transparent !important;
                        color: $red-alert !important;
                        border: none;
                        border-left: 5px solid $white;
                        padding-left: 10px !important;

                        &:hover {
                            text-align: left;
                            background-color: transparent !important;
                            color: $red-alert !important;
                            border: none;
                            border-left: 5px solid $red-alert;
                        }
                    }
                }
            }
        }
    }
}

// Sites Top navigation
.nav {
    &--languages,
    &--sites {
        font-size: .875rem;
    }

    &--sites {
        &.uk-navbar-nav a {
            padding: 0 1rem;
            min-height: 2.25rem;
        }

        .is-active {
            background-color: $white;
        }
    }

    &--languages {
        background-color: transparent;
        text-transform: uppercase;

        &.uk-navbar-nav a {
            font-weight: 700;
            padding: 0 .375rem;
            min-height: auto;
            line-height: 1;
            color: lighten($global-muted-color, 10%);
            border-right: solid 1px lighten($global-muted-color, 10%);
        }

        &.uk-navbar-nav li:last-child a {
            border: none;
        }

        .is-active a {
            color: $global-muted-color;
        }
    }

    &--call {
        margin-left: 1rem;
        font-size: 1rem;
        font-weight: 700;

        a {
            color: $red-alert !important;
            cursor: pointer;

            svg {
                margin-right: .250rem;

                .filling {
                    fill: $red-alert;
                }
            }

            &:hover {
                text-decoration: none;
                color: $red-alert;

                svg .filling {
                    fill: $red-alert;
                }
            }
        }
    }
}

.nav--footer {
    position: relative;
    margin-top: 30px;

    .uk-totop {
        font-size: 2.5rem;
        position: absolute;
        margin: 0;
        padding: 0;
        bottom: -30px;
        right: -40px;

        @media (min-width: $breakpoint-small) {
            right: -40px;    
        }
        @media (min-width: $breakpoint-medium) {
            right: -10px;    
        }
    }


    li.footer-link--top ul {
        margin-bottom: 30px;
    }


    ul.uk-nav-default {

        > li > a {
            font-size: 0.75rem;
            font-weight: 400;
            margin-bottom: 1rem;
            display: inline-block;
            font-weight: 500;
            text-transform: uppercase;

            &:hover,
            &:focus {
                text-decoration: none;
            }
        }

        .uk-navbar-dropdown-nav {
            font-size: 0.75rem;

            a {
                &:hover,
                &:focus {
                    color: $primary-mcs;
                }
            }
        }
    }

    &--helpline {
        ul.uk-nav-default {
            .uk-navbar-dropdown-nav {
                a {
                    &:hover,
                    &:focus {
                        color: $primary-helpline;
                    }
                }
            }
        }
    }
}

.uk-offcanvas-bar {
    background-color: $white;

    .uk-close {
        color: $global-emphasis-color !important;
    }

    &--helpline {
        .nav--languages .is-active a {
            color: $primary-helpline !important;
        }
    }

    &--mcs {
        .nav--languages .is-active a {
            color: $primary-mcs !important;
        }
    }
    .nav {
        &--languages {
            height: auto;
            font-size: 1rem;

            a {
                color: $global-color !important;
            }
        }

        &--main {
            font-size: 1rem;

            > ul > li {
                font-size: 1.125rem;
                margin-bottom: 1rem;
            }

            > ul > li > a {
                color: #666666;

                &.uk-active--mcs {
                    color: $primary-mcs;
                }

                &.uk-active--helpline {
                    color: $primary-helpline;
                }
            }

            > ul > li > ul {
                padding-left: .725rem;

                a {
                    color: #666666;

                    &.uk-active--mcs {
                        color: $primary-mcs;
                    }

                    &.uk-active--helpline {
                        color: $primary-helpline;
                    }
                }
            }
        }

        &--main--donations {

            > ul > li > a {
                color: $primary-helpline;
            }

            > ul > li > ul {
                padding-left: .725rem;

                a {
                    color: $global-color;

                    &.uk-active {
                        color: $primary-helpline;
                    }
                }
            }
        }
    }

    &--mcs {

        .nav--main > ul > li > a {
            &:focus,
            &:hover {
                color: $primary-mcs;
            }
        }
        .uk-navbar-dropdown-nav > li > a {
            color: $navbar-dropdown-nav-item-color;
        }
    }

    &--helpline {

        .nav--main > ul > li > a {
            &:focus,
            &:hover {
                color: $primary-helpline;
            }
        }
        .uk-navbar-dropdown-nav > li > a {
            color: $navbar-dropdown-nav-item-color;
        }
    }
}

.menu-icon {
    margin-right: 1rem;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 16px;
    cursor: pointer;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 2px;
        background: $primary-mcs;
        box-shadow: 0 7px 0 $primary-mcs, 0 14px 0 $primary-mcs;
        content: '';
    }

    &--helpline {
        &:after {
            background: $primary-helpline;
            box-shadow: 0 7px 0 $primary-helpline, 0 14px 0 $primary-helpline;
        }
    }
}

// Sticky logo transition
.uk-active.uk-sticky-fixed {
    .navbar-logo:not(.logo-helpline) {
        opacity: 0;
        visibility: hidden;
    }
    .navbar-logo-desk-small:not(.logo-helpline) {
        opacity: 1;
        visibility: visible;
    }
}