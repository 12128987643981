.container {
    display: block;
    position: relative;
}

@mixin clearfix() {
    &::after,
    &::before {
        display: table;
        content: ' ';
    }

    &::after {
        clear: both;
    }
}

.social-share {
    display: flex;
    justify-content: left;
    width: 100%;
    margin: 0;
    font-size: 0.725em;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;

    a {
        margin-right: 10px;
        padding: 0.5rem 1em;
        color: $white;
        background: transparent;
        font-size: 1.2em;
        transition: all 125ms linear;
        position: relative;
        display: block;
        overflow: hidden;
        text-decoration: none;
        @include clearfix();

        span {
            z-index: 10;
            position: relative;
        }

        i {
            margin-right: 5px;
            z-index: 10;
            position: relative;
        }

        &:after {
            width: 100%;
            height: 100%;
            background: $white;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: block;
            z-index: 0;
            transition: all 125ms linear;
        }

        &:hover {
            &:after {
                transform: translateY(95%);
            }
        }

        &#facebookShare {

            &:after {
                background: #3b5998;
            }

            &:hover {
                color: #3b5998;
            }
        }

        &#twitterShare {
            &:after {
                background: #55acee;
            }

            &:hover {
                color: #55acee;
            }
        }

        &#linkedinShare {
            &:after {
                background: #0976b1;
            }

            &:hover {
                color: #0976b1;
            }
        }

        &#emailShare {
            &:after {
                background: #e50914;
            }

            &:hover {
                color: #e50914;
            }
        }
    }
}
