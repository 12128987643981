
.icon-check:before { content: '\e678'; } /* '' */
.icon-attention-alt:before { content: '\e679'; } /* '' */
.icon-right-small:before { content: '\e801'; } /* '' */
.icon-help-circled:before { content: '\e802'; } /* '' */
.icon-download:before { content: '\e803'; } /* '' */
.icon-plus-circle:before { content: '\e804'; } /* '' */
.icon-minus-circle:before { content: '\e805'; } /* '' */
.icon-lock:before { content: '\e806'; } /* '' */
.icon-cancel:before { content: '\e807'; } /* '' */
.icon-up-open-big:before { content: '\e808'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-facebook-official:before { content: '\f230'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */
.icon-linkedin-rect:before { content: '\f312'; } /* '' */