.news-home__container {
  .section-header {
    margin-top: -4.225rem;
    padding-left: 15px;

    .uk-article-title {
      font-size: 3rem;
      color: $white;
    }
  }

  padding-bottom: 2rem;
}

.fb-home__container {
  position: relative;

  .section-header {
    position: absolute;
    bottom: -5rem;
    right: 0;

    .uk-article-title {
      font-size: 3rem;
      color: $white;
    }
  }
}
