.press__nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media (min-width: $breakpoint-small) {
        justify-content: center;
    }

    &-item {
        @media (min-width: $breakpoint-small) {
            margin: 0 2rem;
        }
    }
}

.timeline {
    $p: &; // Referencer

    position: relative;
    list-style: none;
    margin: 0 auto 30px;
    padding-left: 0;
    width: 100%;
    z-index: 1;

    @media (min-width: $breakpoint-small) {
        width: 90%;
    }

    &__point {
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        order: 1;
        width: 80px;
        text-align: center;

        img {
            filter: grayscale(100%);
        }

        @media (min-width: $breakpoint-small) {
            order: 2;
        }

        .uk-badge {
            min-width: 14px;
            height: 14px;
            background-color: $primary-mcs;

            &--helpline {
                background-color: $primary-helpline;
            }
        }

        &--history {
            margin: 0 1rem;
        }
    }

    &__icon {
        width: 80px;
        height: 80px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $primary-mcs;

        &--helpline {
            background-color: $primary-helpline;
        }

        svg {
            width: 50%;
            height: 50%;

            path {
                fill: $white;
            }
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1.5rem 0;
        position: relative;

        @media (min-width: $breakpoint-small) {
            flex-direction: row;
            justify-content: center;
        }

        &:first-child,
        &.first {
            #{$p}__point {
                &:before {
                    height: 50%;
                    top: 50%;
                }
            }
        }
        &:last-child,
        &.last {
            #{$p}__point {
                &:before {
                    @media (min-width: $breakpoint-small) {
                        height: 50%;
                        bottom: 50%;
                    }
                }
            }
        }

        &.hidden {
            height: 0;
            overflow: hidden;
           -prefix-animation: slide 1s ease 3.5s forwards;
        }


        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            right: 50%;
            width: 1px;
            z-index: -1;
            background-color: #ebebeb;
            display: block;
        }

        &:first-child{
            &:before {
                height: 50%;
                top: 50%;
                bottom: 0;
            }
        }

        &:last-child{
            &:before {
                height: 50%;
                top: 0;
                bottom: 50%;
            }
        }
    }
    &__content {
        padding: 0.5rem 2rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;


        @media (min-width: $breakpoint-small) {
            padding: 0rem;
        }

        .uk-card-body {
            text-align: center;
            padding: 0 0 0.5rem 0;
            background-color: transparent;

            @media (min-width: $breakpoint-small) {
                padding: 0;
            }

            time {
                display: block;
            }
        }

        &--history {
            .uk-card-body {
                padding: 1rem;

                @media (min-width: $breakpoint-small) {
                    padding: 1rem;
                }
            }
        }

        &:not(&--history) {
            background-color: $white;
        }
        
    }

    &__buffer {
        height: 0;
    }

    &--left {
        #{$p}__content {
            order: 1;
            justify-content: right;
        }
        #{$p}__buffer {
            order: 3;
        }
    }
    &--center {
        #{$p}__block {
            &:nth-child(odd) {
                #{$p}__content {
                    order: 2;

                    @media (min-width: $breakpoint-small) {
                        order: 1;
                        align-items: flex-end;
                        text-align: right;
                        
                        .uk-card-body {
                            text-align: right;
                        }
                    }
                }
                #{$p}__buffer {
                    order: 3;
                }
            }
            &:nth-child(even) {
                #{$p}__content {
                    order: 2;
                    @media (min-width: $breakpoint-small) {
                        align-items: flex-start;
                        order: 3;

                        .uk-card-body {
                            text-align: left;
                        }
                    }

                }
                #{$p}__buffer {
                    order: 3;
                    @media (min-width: $breakpoint-small) {
                        order: 1;
                    }
                }
            }
        }
    }
    &--right {
        #{$p}__content {
            order: 3;
        }
        #{$p}__buffer {
            order: 1;
        }
    }
}
