.red-alert {
  color: $red-alert;
}

.green-alert {
  color: $green-alert;
}



/* Fonts
 ========================================================================== */
 

/* Custom bullets
 ========================================================================== */
.uk-card-body ul {
  list-style: none; /* Remove default bullets */
}
.uk-card-body li {
  margin-bottom: 0.5rem;
}
.uk-card-body ul li::before {
  content: "\2022";       /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold;      /* If you want it to be bold */
  display: inline-block;  /* Needed to add space between the bullet and the text */
  width: 1em;             /* Also needed for space (tweak if needed) */
  margin-left: -1em;      /* Also needed for space (tweak if needed) */
}
body.mcs .uk-card-body ul li::before {
  color: $primary-mcs;    /* Change the color */
}

body.helpline .uk-card-body ul li::before {
  color: $primary-helpline;    /* Change the color */
}



span.highlight {
  color: $white;
  background-size: 300% 100%;
  background-image: linear-gradient(270deg,$primary-mcs 60%,transparent 0);
  transition: background-position 1.5s;
  display: table;
  margin-left: -10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 0px;
  background-position: 0 0!important;

  @media (min-width: $breakpoint-large) {
      padding-bottom: 0px;
  }

  &.uk-scrollspy-inview {
      background-position: -85% 0!important;
  }

  &--helpline {
      background-image: linear-gradient(270deg,$primary-helpline 60%,transparent 0);
  }

  &--inlined {
    display: inline-block;
    margin-left: 0rem;
  }
}

h3 span.highlight--inlined {
  // background-image: linear-gradient(270deg,$primary-mcs 60%,transparent 0);
  padding-left: 10px;
  padding-right: 10px;
  // margin-right: 8px;

  &.uk-scrollspy-inview {
      background-position: -50% 0!important;
  }

  &--helpline {
      // background-image: linear-gradient(270deg,$primary-helpline 60%,transparent 0);
  }
}

/*
 * Video player
 */

.video-container {
  background-color: transparent;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.video-container video {
  padding-top: 25px;
}



.video-container-yt {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
}
.video-container-yt img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}
.video-container-yt .play-button {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}
.video-container-yt .play-button:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26.0px;
  border-color: transparent transparent transparent #fff;
}
.video-container-yt img,
.video-container-yt .play-button {
  cursor: pointer;
}
.video-container-yt img,
.video-container-yt iframe,
.video-container-yt .play-button,
.video-container-yt .play-button:before {
  position: absolute;
}
.video-container-yt .play-button,
.video-container-yt .play-button:before {
  top: 50%;
  left: 50%;
  transform: translate3d( -50%, -50%, 0 );
}
.video-container-yt iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.uk-container figure:not(.image) {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;  
}

.uk-container figure iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}


.injected-svg {
    transform: translate(0, 0);
    fill: currentcolor;

    &.button-call {
        width: 50px;
    }
}


/* Forms
 ========================================================================== */

form .errors.uk-list {
  margin-top: 6px;

  li {
    @extend .uk-text-danger;
  }
} 

body.en .message-en {
  display: block!important;
}
body.fr .message-fr {
  display: block!important;
}
body.de .message-de {
  display: block!important;
}
body.it .message-it {
  display: block!important;
}


/* Overides for some pages
 ========================================================================== */

body.entry-348 .uk-card-title {
  padding: 0 2rem;
}


/* news
 ========================================================================== */
.news-aside .uk-heading-small {
  text-transform: uppercase;
}
