.filter-sepia {
    filter: sepia(1);
}

.hero {
    display: inline-block;
    position: relative;
    max-width: 100%;
    margin-bottom: 0;
    vertical-align: middle;
    -webkit-backface-visibility: hidden;

    @media (min-width: $breakpoint-small) {
        margin-bottom: 0rem;
    }

    @media (min-width: $breakpoint-medium) {
        margin-bottom: 0;
    }

    &--with-text {
        @media (max-width: $breakpoint-small) {
            margin-bottom: 1rem;
        }
    }

    &--no-image {
        
        @media (min-width: $breakpoint-small) {
            margin-bottom: 2rem;
        }
    }

    &__picture {
        @media (min-width: $breakpoint-small) {
            max-height: 375px;
            overflow: hidden;
        }
        @media (min-width: $breakpoint-medium) {
            max-height: 625px;
            overflow: hidden;
        }
        @media (min-width: $breakpoint-xlarge) {
            max-height: 825px;
            overflow: hidden;
        }

        &--news {
            picture img {
                filter: sepia(1);
            }
        }
    }

    &__contents {

        color: $global-color-alt;

        @media (min-width: $breakpoint-small) {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;

            position: absolute;
            bottom: 1rem;
            left: 0;
            right: 0;
        }

        @media (min-width: $breakpoint-large) {
            bottom: 3.75rem;
        }

        &--with-text {

            @media (max-width: $breakpoint-small) {
                // position: initial;
                // bottom: 6.5rem;
            }
            @media (min-width: $breakpoint-small) {
                // bottom: 9.5rem;
            }
            @media (min-width: $breakpoint-medium) {
                // bottom: 9.75rem;
            }
            @media (min-width: $breakpoint-large) {
                // bottom: 3.75rem;
            }

            .hero__title {
                // margin-bottom: 2rem;

                @media (min-width: $breakpoint-small) {
                    // margin-bottom: 2.5rem;
                }
                @media (min-width: $breakpoint-medium) {
                    margin-bottom: 2rem;
                }
                @media (min-width: $breakpoint-large) {
                    margin-bottom: 0;
                }
            }
        }

        &--no-image {
            position: relative;
            margin-top: 0;
            bottom: 0;

            @media (min-width: $breakpoint-medium) {
                margin-top: 4rem;
            }
        }
    }

    &__headers {
        margin-top: 1rem;
        color: $global-color-alt;

        p {
            font-size: 1.250rem;
        }

        @media (min-width: $breakpoint-small) {
            margin-top: 2rem;
            color: $white;

            p {
                font-size: 1.125rem;
            }
        }

        &--dark {
            color: $global-color-alt;
        }
    }

    &__teaser {
        position: relative;
        bottom: -35px;

        .uk-card--badged {
            margin-bottom: 0;
        }
    }

    &__title {
        line-height: 1.25;
        color: $global-color-alt;
        font-size: 2.25rem;
        font-weight: 700;

        @media (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
            font-size: 2.125rem;
        }
        @media (min-width: $breakpoint-small) {
            color: $white;
            // font-size: 4.813rem;
        }
        @media (min-width: $breakpoint-medium) {
            color: $white;
            font-size: 3.125rem;
        }
        @media (min-width: $breakpoint-large) {
            color: $white;
            font-size: 3.850rem;
        }

        &--dark {
            color: $titles-color;
        }
    }

    &__buttons {
        &-container {
            flex-direction: column;

            @media (min-width: $breakpoint-small) {
                flex-direction: row;
            }

            @media (min-width: $breakpoint-large) {
                > * {
                    padding-left: 0;
                }
            }
        }

        flex-direction: row;

        @media (min-width: $breakpoint-small) {
            flex-direction: column;
            padding-left: 1rem;

            .uk-card {
                margin-bottom: 1rem;
            }


        }

        @media (min-width: $breakpoint-medium) {
            padding-left: 0;
            
            a {
                margin-top: 1rem;
            }
        }

        @media (min-width: $breakpoint-small) and (max-width: $breakpoint-large) {
            .uk-card {
                margin-right: 1rem;

                &:first-child {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
